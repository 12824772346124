import { Link } from 'gatsby'
import React from 'react'
import * as styles from '../styles/footer.module.css'

export default function FooterComponent() {
  return (
    <footer className={styles.footer}>
        <div className={styles.socialMediaLinks}>
				<ul>
					<li>
						<Link to="https://www.facebook.com/OFFICIALVentureGames" target="_blank">
							<img src="/socialMedia/FacebookLogo_Black.png" alt="Facebook"/>
						</Link>
					</li>
					<li>
						<Link to="https://twitter.com/games_venture" target="_blank">
							<img src="/socialMedia/TwitterLogo_Black.png" alt="Twitter"/>
						</Link>
					</li>
					<li>
						<Link to="https://www.instagram.com/venture_games/" target="_blank">
							<img src="/socialMedia/InstagramLogo_Black.png" alt="Instagram"/>
						</Link>
					</li>
				</ul>
			</div>
			<p>&copy;2022 Venture Games</p>
    </footer>
  )
}
