import React from 'react';
import Layout from '../components/Layout'
import * as styles from '../styles/404.module.css'

export default function PageNotFound() {
  return (
      <Layout>
        <div className={styles.noPage}>
            <h2>404</h2>
            <h3>Sorry, this page does not exist!</h3>
        </div>
      </Layout>
  )
}
