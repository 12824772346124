import React from 'react';
import Navbar from './Navbar';
import '../styles/global.css';
import FooterComponent from './Footer';

export default function Layout({children}) {
  return (
    <div className="layout">
        <Navbar/>
        <div className="content">
            {children}
        </div>
        <FooterComponent/>
    </div>
  )
}
