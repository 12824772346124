import { Link } from 'gatsby';
import React, { useState } from 'react';

export default function Navbar() {

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
      <nav>
        <Link className="vg-logo" to="/"><img src="/VentureGamesLogo.png" alt="Venture Games Logo"/></Link>
        <ul className="navBar">
          <li><Link className="navLink navLink-ltr" to="/games">games</Link></li>
          <li><Link className="navLink navLink-ltr" to="/about">about</Link></li>
          <li><Link className="navLink navLink-ltr" to="/contact">contact</Link></li>
        </ul>
        <div className={click ? "hamburger-icon open" : "hamburger-icon"} onClick={handleClick} role="button">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <ul className={click ? "mobile-menu-links open" : "mobile-menu-links"}>
            <li><Link className="navLink navLink-ltr" to="/games" onClick={closeMobileMenu}>games</Link></li>
            <li><Link className="navLink navLink-ltr" to="/about" onClick={closeMobileMenu}>about</Link></li>
            <li><Link className="navLink navLink-ltr" to="/contact" onClick={closeMobileMenu}>contact</Link></li>
          </ul>
      </nav>
  )
}
